import NavBar from "./components/NavBar";
import LaunchList from "./components/LaunchList";
import "./App.css";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <LaunchList/>
    </div>
  );
}

export default App;
